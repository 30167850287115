<!--
File: RoadTypesDropdown.vue
Description: show the dropdown combo with the Road Types list (paved/unpaved) for showing in the map.
-->
<template>
  <base-dropdown v-model="selectedValue" :label="label || $t('label.road_type')" :items="roadTypes"
    :isRequired="isRequired" :disabled="disabled" :dropdownId="'roadTypesDropdown'" @input="onChange">
  </base-dropdown>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import BaseDropdown from './BaseDropdown.vue'

  export default {
    name: 'road-types-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: Number },
      isRequired: { default: false, type: Boolean },
      regionId: { default: null, type: Number },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,
        listLoaded: false,
      }
    },

    components: {
      BaseDropdown,
    },

    async mounted() {
      await this.reloadData();
    },

    methods: {
      ...mapActions({
        getList: 'GET_ROAD_TYPES_DROPDOWN'
      }),

      async reloadData() {
        const res = await this.getList()
        if (res === 'success') {
          this.listLoaded = true;
          if (this.value && !this.selectedValue) this.selectedValue = this.value;
        }
      },

      onChange(value, description) {
        this.$emit('input', value, description);
      }
    },

    computed: {
      ...mapState({
        roadTypes: (state) => state.Dropdowns.road_types
      })
    },

    watch: {
      value(newValue, oldValue) {
        if (newValue !== oldValue && this.listLoaded) {
          this.selectedValue = newValue;
        }
      }
    }
  }
</script>